import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { IFacets } from "@/interfaces/IFacets";
import { IRg } from "@/interfaces/IRg";
import { IEquipmentCategory } from "@/interfaces/IEquipment";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import { ISelectData } from "@/interfaces/ISelectData";
import { fetchFacets } from "@api";

/*****STATES*****/
interface StoreState {
  vat: number;
  customerConfiguration: ICustomerConfiguration | null;
  facets: IFacets | null;
  rg: IRg | null;
  bearerToken: string | null;
  equipments: IEquipmentCategory[] | null;
  listSettings: {
    page: number;
    perPage: number;
    sort: string;
    order: boolean;
    view: number;
    menuOpen: boolean;
  };
  vehicles: { count: number; cars: IVehicleListItem[]; cV?: number };
  searchObject: ISelectData;
  error: string;
  isDataFetching: boolean;
}

const initializeState = (): StoreState => ({
  vat: 19,
  customerConfiguration: null,
  facets: null,
  rg: null,
  bearerToken: null,
  equipments: null,
  listSettings: {
    page: 1,
    perPage: 30,
    sort: "new",
    order: true,
    view: 1,
    menuOpen: false
  },
  vehicles: { count: 0, cars: [], cV: 19 },
  searchObject: {
    sort: "new",
    sortd: "ASC"
  },
  error: "",
  isDataFetching: true
});

/*****ACTIONS*****/
interface StoreActions {
  setCustomerConfiguration: (value: ICustomerConfiguration) => void;
  setFacets: (value: IFacets) => void;
  setRg: (value: IRg) => void;
  setBearerToken: (value: string) => void;
  setEquipments: (value: IEquipmentCategory[]) => void;
  setListSort: (value: string) => void;
  setListOrder: (value: boolean) => void;
  setListPage: (value: number) => void;
  setListPerPage: (value: number) => void;
  setListView: (value: number) => void;
  setListMenu: (value: boolean) => void;
  setVehicles: (value: { count: number; cars: IVehicleListItem[]; cV?: number }) => void;
  setSearchObject: (value: ISelectData) => void;
  setError: (value: string) => void;
  fetchFacetData: () => void;
  setIsDataFetching: (value: boolean) => void;
}

const initializeActions = (set: any, get: any): StoreActions => ({
  setCustomerConfiguration: (value) => {
    console.log("customerConfiguration:", value);
    set({ customerConfiguration: value });
  },
  setFacets: (value) => {
    console.log("facets:", value);
    set({ facets: value });
  },
  setRg: (value) => set({ rg: value }),
  setBearerToken: (value) => set({ bearerToken: value }),
  setEquipments: (value) => set({ equipments: value }),
  setListSort: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, sort: value }
    })),
  setListOrder: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, order: value }
    })),
  setListPage: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, page: value }
    })),
  setListPerPage: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, perPage: value }
    })),
  setListView: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, view: value }
    })),
  setListMenu: (value) =>
    set((state: StoreState) => ({
      listSettings: { ...state.listSettings, menuOpen: value }
    })),
  setVehicles: (value) => {
    if (value?.cV) {
      set({ vat: value.cV });
    }
    set({ vehicles: value || initializeState().vehicles });
  },
  setSearchObject: (value) => {
    console.log("searchObject:", value);
    set({ searchObject: value });
  },
  setError: (value) => set({ error: value }),
  fetchFacetData: async () => {
    const { searchObject, customerConfiguration, setFacets, setRg, setVehicles, setError, setIsDataFetching } = get();
    setIsDataFetching(true);
    try {
      const queryParams = searchObject;
      const minimized = location.pathname === customerConfiguration?.pages?.quickSearch || location.pathname === customerConfiguration?.pages?.detailSearch;
      const data: any = await fetchFacets(queryParams, minimized);

      setFacets(data.facets);
      setRg(data.rg);
      setVehicles(data.searchResult);
    } catch (error) {
      setError("Fahrzeuge konnten nicht aufgerufen werden. Bitte versuchen Sie es später nochmal.");
      console.error(error);
    } finally {
      setIsDataFetching(false);
    }
  },
  setIsDataFetching: (value) => set({ isDataFetching: value })
});

const useStore = create<StoreState & StoreActions>()(
  persist(
    (set, get) => ({
      ...initializeState(),
      ...initializeActions(set, get)
    }),
    {
      name: "dotzilla-mpi-storage",
      version: 2.1, // Increment this number to invalidate old stored state
      migrate: () => {
        return initializeState();
      },
      // convert values after rehydration
      onRehydrateStorage: () => (state) => {
        if (state) {
          state.error = "";
        }
      }
    }
  )
);

export default useStore;
