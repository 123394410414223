import { useEffect, useState } from "react";
import { deepmerge } from "@mui/utils";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import MainContainer from "./MainContainer";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { Alert, Container, ScopedCssBaseline, Snackbar } from "@mui/material";
import { convertHexToRGBA, parseQueryParams, createQueryParams } from "./tools/tools";
import { defaultConfigTheme, customStyles } from "./defaultConfigTheme";
import useStore from "@store";
import { GlobalStyles } from "@mui/system";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import ExternalScriptContainer from "./components/Externals/ExternalScriptContainer";
import { fetchInit } from "@api";
import { useNavigate } from "react-router-dom";

const App = () => {
  const { customerConfiguration, setCustomerConfiguration, setEquipments, searchObject, setSearchObject, error, setError } = useStore();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [theme, setTheme] = useState(createTheme(defaultConfigTheme));
  const location = useLocation();
  const navigate = useNavigate();

  // Read filter parameter from URL and update search object
  useEffect(() => {
    const queryParams = parseQueryParams(location.search);
    if (queryParams && Object.keys(queryParams).length) {
      setSearchObject(queryParams);
    }
  }, []);

  // Update filter paramter in URL
  useEffect(() => {
    const queryParams = createQueryParams(searchObject);
    navigate({ search: `?${queryParams}` }, { replace: true });
  }, [searchObject]);

  // Fetching Init Data
  useEffect(() => {
    const fetchInitData = async () => {
      try {
        const data: any = await fetchInit();

        setEquipments(data.ausstattungen);
        setThemeConfig(data.config);
        setCustomerConfiguration(data.config);
      } catch (error) {
        setError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.");
      } finally {
        setIsDataLoaded(true);
      }
    };
    fetchInitData();
  }, [useStore]);

  // Set User theme configuration
  const setThemeConfig = (config: ICustomerConfiguration) => {
    const theme1 = createTheme(config && config.styles.mui ? deepmerge(defaultConfigTheme, config.styles.mui || {}) : defaultConfigTheme);

    const theme2 = {
      palette: {
        action: {
          active: theme1.palette.grey[500],
          hover: convertHexToRGBA(theme1.palette.primary.main, 0.1),
          selected: convertHexToRGBA(theme1.palette.primary.main, 0.1),
          disabled: theme1.palette.grey[500],
          disabledBackground: theme1.palette.grey[200]
        }
      },
      components: {
        MuiTab: {
          styleOverrides: {
            root: {
              color: theme1.palette.secondary.main,
              "&.Mui-selected": {
                backgroundColor: theme1.palette.primary.main
              }
            }
          }
        }
      }
    };

    setTheme(responsiveFontSizes(createTheme(deepmerge(theme1, theme2))));

    // set html root font size (default: 16px)
    document.documentElement.style.fontSize = config?.styles?.custom?.fontSize || "16px";
  };

  // scroll to top after each route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // hide custom elements after each route change
  useEffect(() => {
    if (location.pathname !== "/") {
      const hideElements = customerConfiguration?.styles?.hidden;
      hideElements?.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          (element as HTMLElement).style.display = "none";
        });
      });
    }
  }, [location.pathname]);

  // Snackbar trigger
  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  // Snackbar close handler
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setError("");
  };

  const sxContainer = customerConfiguration?.styles?.custom?.sxContainer || {};

  return (
    <ScopedCssBaseline sx={{ background: "transparent" }}>
      <ThemeProvider theme={theme}>
        <Container id="mpi-container" sx={{ padding: { xs: 0 }, textAlign: "left", ...sxContainer }}>
          {error && (
            <Snackbar
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{
                position: "relative",
                top: 30,
                left: "50%",
                marginBottom: 60,
                transform: "translateX(-50%)"
              }}
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="error">
                {error}
              </Alert>
            </Snackbar>
          )}
          {isDataLoaded && customerConfiguration && <MainContainer customerConfig={customerConfiguration} />}
          {isDataLoaded && customerConfiguration && <ExternalScriptContainer customerConfig={customerConfiguration} />}
          <Footer />
        </Container>
      </ThemeProvider>
      <GlobalStyles styles={customStyles} />
    </ScopedCssBaseline>
  );
};

export default App;
