import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import useStore from "@store";
import DebugLinks from "./components/Debug/DebugLinks";
import { Box } from "@mui/material";
import { TYPE_NEW_VEHICLE, TYPE_USED_VEHICLE } from "./constants";

//views
const QuickSearch = lazy(() => import("@/views/QuickSearch/QuickSearch"));
const VehicleList = lazy(() => import("@/views/VehiclesList/VehicleList"));
const VehicleDetail = lazy(() => import("@/views/VehicleDetail/VehicleDetail"));
const DetailSearch = lazy(() => import("@/views/DetailSearch/DetailSearch"));
const VehicleSlider = lazy(() => import("@/views/VehicleSlider/VehicleSlider"));
const Configurator = lazy(() => import("@/views/Admin/Configurator/Configurator"));
const Login = lazy(() => import("@/views/Admin/Login/Login"));
const Setup = lazy(() => import("@/views/Admin/Setup/Setup"));
const Sitemap = lazy(() => import("@/views/Sitemap/Sitemap"));
const Testo = lazy(() => import("@/views/Testo/Testo"));
const NotFoundPage = lazy(() => import("@/views/NotFound404/NotFoundPage"));

interface MainContainerProps {
  customerConfig: ICustomerConfiguration;
}

const MainContainer: React.FC<MainContainerProps> = ({ customerConfig }) => {
  const { bearerToken } = useStore();

  const hasTestoQuery = new URLSearchParams(location.search).get("testo") === "testo";

  return (
    <>
      <Suspense
        fallback={
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress aria-label="Lädt..." />
          </Box>
        }
      >
        {import.meta.env.VITE_APP_DEBUG === "true" && <DebugLinks customerConfig={customerConfig} />}
        <Routes>
          {hasTestoQuery && (
            <>
              {/* TODO: Delete soon */}
              <Route path={customerConfig.pages.detailSearch} element={bearerToken ? <Configurator customerConfig={customerConfig} /> : <Login />} />
              <Route path={"mpi-admin/login"} element={<Login />} />
            </>
          )}

          <Route path={customerConfig.pages.quickSearch} element={<QuickSearch />} />
          <Route path={customerConfig.pages.quickSearchUsed} element={<QuickSearch forcedConditionSelection={TYPE_USED_VEHICLE} />} />
          <Route path={customerConfig.pages.quickSearchNew} element={<QuickSearch forcedConditionSelection={TYPE_NEW_VEHICLE} />} />
          <Route path={`${customerConfig.pages.vehicleDetail}/:vehicleTy/:vehicleId/*`} element={<VehicleDetail />} />
          <Route path={customerConfig.pages.vehicleList} element={<VehicleList />} />
          <Route path={customerConfig.pages.detailSearch} element={<DetailSearch />} />
          <Route path={customerConfig.pages.vehicleSlider} element={<VehicleSlider />} />
          <Route path="mpi-admin/config" element={bearerToken ? <Configurator customerConfig={customerConfig} /> : <Login />} />
          {import.meta.env.VITE_APP_DEBUG === "true" && (
            <>
              <Route path={"mpi-admin/setup"} element={<Setup />} />
              <Route path={"testo"} element={<Testo />} />
            </>
          )}
          <Route path={"mpi-admin/login"} element={<Login />} />
          <Route path={"mpi-sitemap"} element={<Sitemap />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export default MainContainer;
