import { ISelectData } from "@/interfaces/ISelectData";
import { removePropertiesWithValueAlle } from "@/tools/tools";

export function getBackend(path: string) {
  return import.meta.env.VITE_APP_API_URL + path;
}

export function getBackendApi(path: string) {
  return import.meta.env.VITE_APP_API_URL + "/proxy/v1/" + path;
}

export function getBackendApi2(path: string) {
  return import.meta.env.VITE_APP_API_URL + "/proxy/v2/" + path;
}

export function getBackendApi3(path: string) {
  return import.meta.env.VITE_APP_API_URL + "/proxy/v3/" + path;
}

const customFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const response = await fetch(input, init);

  switch (response.status) {
    case 400:
      throw new Error(`Network error: ${response.statusText}`);

    case 401:
      const location = response.headers.get("Location");
      if (location) {
        window.location.href = location;
      }
      throw new Error("Unauthorized: Redirecting to login.");

    case 403:
      const error403 = await response.json();
      console.error("API Error 403: Access forbidden", error403);
      throw new Error("Forbidden: You do not have access to this resource.");
  }
  return response;
};

// Hilfsfunktion zum Generieren der URL
const generateHTTPUrl = (searchObject: ISelectData, baseUrl: string) => {
  const facetsAND = ["ai"]; // Facetten, die mit Kommas verbunden werden sollen
  const queryParams: Record<string, string> = {};
  const collectedValues: Record<string, string> = {};

  // Sammle alle Werte für jeden Key
  Object.entries(searchObject).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const joinedValues = value.join(facetsAND.includes(key) ? "," : ".");
      collectedValues[key] = joinedValues;
    } else {
      collectedValues[key] = value.toString();
    }
  });

  // Konvertiere gesammelte Werte in Query-Parameter
  Object.entries(collectedValues).forEach(([key, value]) => {
    queryParams[key] = `${key}=${value}`;
  });

  const queryString = Object.values(queryParams).join("&");
  return baseUrl + "?" + queryString;
};

export const fetchInit = async () => {
  const response = await customFetch(getBackendApi(`init`));
  return response.json();
};

export const fetchFacets = async (searchObject: ISelectData, minimized: boolean) => {
  const baseUrl = minimized ? import.meta.env.VITE_APP_API_URL + "/proxy/v3/cars1" : import.meta.env.VITE_APP_API_URL + "/proxy/v3/cars";

  // Entferne "alle" und leere Werte
  removePropertiesWithValueAlle(searchObject);

  // Generiere die URL
  const searchObjectUrl = generateHTTPUrl(searchObject, baseUrl);

  try {
    const response = await fetch(searchObjectUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const arrayBuffer = await response.arrayBuffer();
    const data = JSON.parse(new TextDecoder("UTF-8").decode(arrayBuffer));

    return data;
  } catch (error) {
    console.error("Error fetching facets:", error);
    throw error;
  }
};
